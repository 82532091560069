<template>
  <div class="careers-page">
    <section class="careers-hero-section mb-5">
      <div class="careers-hero-img">
        <img
          :src="require('@/assets/images/banner/banner-1.jpg')"
          alt="Revivify Logo"
          class="img-fluid"
        />
      </div>
      <div class="careers-hero-content">
        <h1>CAREERS</h1>
        <p>
          Revivify is here to help you effortlessly sell your luxury goods.
          Here’s how we make it happen.
        </p>
        <button class="btn btn-white">Get Started</button>
      </div>
    </section>
    <section class="careers-job-post">
      <div class="row">
        <div class="col-lg-8 job-card">
          <h3 class="main-sm-heading">
            SOCIAL MEDIA MANAGER, REMOTE SALARY £DOA
          </h3>
          <p>
            Curabitur at molestie arcu. Duis in metus mattis, varius enim quis,
            commodo turpis. Vivamus vitae libero eget libero gravida pulvinar.
            Cras dictum mattis efficitur. Nulla non laoreet velit. In sed nunc
            elementum, maximus odio eu, tincidunt augue.
          </p>
          <button class="btn btn-transparent">Get Started</button>
        </div>
        <div class="col-lg-8 job-card">
          <h3 class="main-sm-heading">
            SOCIAL MEDIA MANAGER, REMOTE SALARY £DOA
          </h3>
          <p>
            Curabitur at molestie arcu. Duis in metus mattis, varius enim quis,
            commodo turpis. Vivamus vitae libero eget libero gravida pulvinar.
            Cras dictum mattis efficitur. Nulla non laoreet velit. In sed nunc
            elementum, maximus odio eu, tincidunt augue.
          </p>
          <button class="btn btn-transparent">Get Started</button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
  },
};
</script>

<style>
.careers-page h1,
.careers-page h3,
.careers-page p {
  margin-bottom: 0px !important;
}
.careers-page p {
  font-size: 1.2rem;
  line-height: 25px;
  color: #000 !important;
  letter-spacing: 0.5px;
}
.careers-page .main-sm-heading {
  color: #000 !important;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 20px;
}
.careers-page .btn-transparent {
  font-size: 16px;
  color: #000 !important;
  border-bottom: 1px solid #000;
  border-radius: 0px;
  padding: 10px 0;
}

.careers-hero-section {
  background: #000;
  position: relative;
}
.careers-hero-img {
  opacity: 0.3;
}
.careers-hero-img img {
  min-height: 400px;
}
.careers-hero-content {
  position: absolute;
  color: #fff !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.careers-hero-content h1 {
  color: #fff;
  padding-bottom: 20px;
  font-size: 40px;
  letter-spacing: 2px;
}
.careers-hero-content p {
  color: #fff !important;
  padding-bottom: 25px;
}
.careers-hero-content .btn-white {
  background: white;
  border-radius: 0px;
  color: #000;
}

.careers-job-post .job-card {
  border-bottom: 2px solid #f0ebe3 !important;
  padding-bottom: 40px;
  padding-top: 20px;
}
@media (min-width: 1400px) {
  .careers-hero-content {
    width: 50%;
  }
  .careers-sm-hero-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>